import { Image } from "@clipboard-health/ui-react";

export function CbhLogo() {
  return (
    <Image
      src="/assets/logo/cbh-logo.png"
      alt="Clipboard Logo"
      sx={{
        width: "100px",
      }}
    />
  );
}
