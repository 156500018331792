import { InternalLink, Span } from "@clipboard-health/ui-react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Alert, type AlertColor, IconButton } from "@mui/material";
import { useState } from "react";
import { type LinkProps } from "react-router-dom";

export interface BannerLinkProps {
  children: string;
  title?: string;
  to: LinkProps["to"];
  severity: AlertColor;
  onClose?: () => void;
  onClick?: () => void;
  canBeMinimized?: boolean;
  defaultExpanded?: boolean;
}

export function BannerLink(props: BannerLinkProps) {
  const {
    children,
    title,
    to,
    severity,
    onClose,
    onClick,
    canBeMinimized = false,
    defaultExpanded = true,
  } = props;

  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const handleToggle = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  if (!canBeMinimized || isExpanded) {
    return (
      <Alert
        severity={severity}
        icon={false}
        sx={{
          paddingLeft: 0,
          paddingY: 0,
          borderRadius: 0,
          width: "100%",
          "& .MuiAlert-action": {
            paddingX: 0,
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          },
          "& .MuiAlert-message": { padding: 0, width: "100%" },
        }}
        action={
          canBeMinimized ? (
            <IconButton sx={{ color: "inherit" }} onClick={handleToggle}>
              <ExpandLess />
            </IconButton>
          ) : undefined
        }
        variant="filled"
        onClose={onClose}
      >
        <InternalLink
          to={to}
          underline="none"
          color="inherit"
          sx={{
            paddingLeft: "16px",
            paddingY: 1,
            height: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              color: "inherit",
            },
          }}
          onClick={onClick}
        >
          {title && (
            <Span display="block">
              <b>{title}</b>
            </Span>
          )}
          {children}
        </InternalLink>
      </Alert>
    );
  }

  const previewText = children.split("\n")[0];

  return (
    <Alert
      severity={severity}
      icon={false}
      sx={{
        paddingLeft: 0,
        paddingY: 0,
        borderRadius: 0,
        width: "100%",
        height: "32px",
        display: "flex",
        alignItems: "center",
        "& .MuiAlert-action": {
          paddingX: 0,
          marginY: 0,
          height: "32px",
          display: "flex",
          gap: 0.5,
          alignItems: "center",
        },
        "& .MuiAlert-message": {
          padding: 0,
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        },
      }}
      action={
        <IconButton sx={{ color: "inherit" }} onClick={handleToggle}>
          <ExpandMore />
        </IconButton>
      }
      variant="filled"
      onClose={onClose}
    >
      <Span
        sx={{
          ml: 2,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flex: 1,
        }}
      >
        {title && <b>{title}: </b>}
        {previewText}
      </Span>
    </Alert>
  );
}
