import constate from "constate";
import { useRef } from "react";

function useIonApp() {
  const ionAppRef = useRef<HTMLIonAppElement>(null);

  return {
    ionAppRef,
  };
}

export const [IonAppProvider, useIonAppContext] = constate(useIonApp);
